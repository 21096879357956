var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agenda"},[_c('div',{staticClass:"agendItem"},[_c('el-dialog',{staticClass:"videoPlayDialog",attrs:{"title":_vm.title ? _vm.title : _vm.$t('meetingAppointment'),"visible":_vm.orderVisible,"width":"700px"},on:{"close":function($event){return _vm.orderVisibleChange()}}},[_c('el-form',{attrs:{"label-width":_vm.LOCALE == 'en' ? '160px' : '90px'}},[_c('el-form-item',{staticClass:"time",attrs:{"prop":"date"}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelSlot"},[_vm._v(_vm._s(_vm.$t("appointmentData")))])]),_c('el-date-picker',{attrs:{"picker-options":_vm.pickerOptions,"type":"date","size":"small","placeholder":_vm.$t('appointmentData')},on:{"change":_vm.handleDay},model:{value:(_vm.agendaForm.day),callback:function ($$v) {_vm.$set(_vm.agendaForm, "day", $$v)},expression:"agendaForm.day"}})],2),_c('el-form-item',{staticClass:"time",attrs:{"prop":"end_time"}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelSlot"},[_vm._v(_vm._s(_vm.$t("appointmentTime")))])]),_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:10',
              end: '23:20',
              minTime: _vm.date,
            },"size":"small","format":"HH:mm","value-format":"HH:mm","placeholder":_vm.$t('beginTime')},on:{"change":_vm.handleStartTime},model:{value:(_vm.agendaForm.start_time),callback:function ($$v) {_vm.$set(_vm.agendaForm, "start_time", $$v)},expression:"agendaForm.start_time"}}),_c('span',[_vm._v("-")]),_c('el-time-select',{attrs:{"size":"small","placeholder":_vm.$t('endTime'),"format":"HH:mm","value-format":"HH:mm","picker-options":{
              start: '00:10',
              step: '00:10',
              end: '23:50',
              minTime: _vm.agendaForm.start_time ? _vm.agendaForm.start_time : _vm.date,
            }},model:{value:(_vm.agendaForm.end_time),callback:function ($$v) {_vm.$set(_vm.agendaForm, "end_time", $$v)},expression:"agendaForm.end_time"}})],2),(_vm.showjobPpositionList)?_c('el-form-item',{attrs:{"prop":"interviewPosition"}},[_c('template',{slot:"label"},[_c('div',{staticClass:"labelSlot"},[_vm._v(_vm._s(_vm.$t("jobInterview")))])]),_c('el-select',{staticStyle:{"width":"50%"},attrs:{"size":"small","placeholder":_vm.$t('pleaseSelect'),"clearable":true},model:{value:(_vm.agendaForm.position_id),callback:function ($$v) {_vm.$set(_vm.agendaForm, "position_id", $$v)},expression:"agendaForm.position_id"}},_vm._l((_vm.jobPpositionList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],2):_vm._e(),(
            _vm.PJSource !== 13 &&
            _vm.PJSource !== 12 &&
            _vm.PJSource !== 7 &&
            _vm.PJSource !== 26 &&
            _vm.PJSource !== 16 &&
            _vm.PJSource !== 14 &&
            _vm.PJSource !== 18 &&
            _vm.PJSource !== 27 &&
            _vm.PJSource !== 29 &&
            _vm.PJSource !== 30 &&
            _vm.PJSource !== 34 &&
            _vm.PJSource !== 36
            
          )?_c('el-form-item',{attrs:{"prop":"send_postscript","label":_vm.$t('notes')}},[_c('el-input',{attrs:{"type":"textarea","placeholder":_vm.$t('entercontent'),"maxlength":"200"},model:{value:(_vm.agendaForm.send_postscript),callback:function ($$v) {_vm.$set(_vm.agendaForm, "send_postscript", $$v)},expression:"agendaForm.send_postscript"}})],1):_vm._e(),_c('el-form-item',[_c('div')])],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.orderVisibleChange()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('el-button',{attrs:{"type":"primary","size":"small","loading":_vm.btnLoading},on:{"click":_vm.confimAgenda}},[_vm._v(_vm._s(_vm.$t("confirm")))])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }